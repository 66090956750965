// MENU
let menu = document.querySelector('.menu');

menu.addEventListener('click', () => {
	let showMenu = document.querySelector('nav'),
			boxHeader = document.querySelector('.header'),
			iconMenu = document.querySelector('.box-menu-icon');
	
	showMenu.classList.toggle('is-open');
	boxHeader.classList.toggle('menu-is-open');
	iconMenu.classList.toggle('is-clicked');
})
